import Mock from "../mock";

const database = {
  information: {
    name: 'Biruk Yemane',
    aboutContent: "I'm a full-stack web developer.",
    //age: 24,
    phone: '+1 641-233-9310',
    //nationality: 'Ethiopian',
    language: 'English, Amahric, Finnish',
    email: 'yeman.biruk@gmail.com',
    address: '1000 N 4th St, Fairfield, Iowa, USA',
    freelanceStatus: 'Available',
    socialLinks: {
      //facebook: 'https://facebook.com',
      //twitter: 'https://twitter.com',
      pinterest: '',
      behance: '',
      linkedin: '',
      dribbble: '',
      github: 'https://github.com/birukyemane'
    },
    brandImage: '/images/biruk-brand.jpeg',
    aboutImage: '/images/biruk-about.jpg',
    aboutImageLg: '/images/biruk-about-lg.jpg',
    cvfile: '/files/CV.pdf'
  },
  services: [
    {
      title: "Web Design",
      icon: 'brush-alt',
      details: " I can provide clean code and pixel perfect design. I also make website more & more interactive with web animations."
    },
    {
      title: "Web Development",
      icon: 'code',
      details: "I'm a web application develper based in Iowa. I have a passion for creating intiutive and dyanmci websites. Let's make something special."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company'
      }
    },
    {
      id: 2,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: 'Susan Yost',
        designation: 'Client'
      }
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: 'Irving Feeney',
        designation: 'Fiverr Client'
      }
    }
  ],
  skills: [
    {
      title: "HTML5",
      value: 95
    },
    {
      title: "Javascript",
      value: 90
    },
    {
      title: "Node.js",
      value: 70
    },
    {
      title: "ReactJS",
      value: 85
    },
    {
      title: "CSS3",
      value: 80
    },
    {
      title: "AWS",
      value: 65
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Dynamic pricing",
      subtitle: "Your All-in-one Dynamic Pricing Solution",
      imageUrl: "/images/portfolio1-pricff.png",
      largeImageUrl: ["/images/portfolio1-pricff-lg.png"],
      url: 'https://www.priceff.com/'
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2020 - 2022",
        position: "Full-stack developer",
        company: "CodingForAll.io",
        details: "I mainly worked on projects focusing on React.js and supporting the back-end development whenever required. I Cooperated with designers to create and implement clean interfaces and simple, intuitive interactions and experiences."
      },
      {
        id: 2,
        year: "2019 - 2020",
        position: "Full-stack developer",
        company: "Priceff",
        details: "My responsibilites include develop features to the React front-end and develop new APIs and improve the AWS Lambda Node.js backend. I also developed and run test suites to ensure the quality of the service."
      },
      {
        id: 3,
        year: "2018 - 1019",
        position: "Junior web developer",
        company: "Intergrify Oy",
        details: "I was part of Integrify's one year full-time mentoring program which gave me the opportunity to work on real client projects. The focus is JavaScript, React/Redux, Node/Express, and MongoDB"
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2022 - present",
        graduation: "MS in Computer Science",
        university: "Maharishi International University, USA",
        details: "A unique computer professioinals masters porgram which teaches the latest programming technologies used in the industry."
      },
      {
        id: 2,
        year: "2012 - 2017",
        graduation: "MS in Software development",
        university: "Tampere University, Finland",
        details: "Provides students with a solid understanding in modern software development.S"
      }
    ]
  },
  blogs: [
    {
      id: 2,
      title: 'Deploy a React App to Amazon S3 using Github Actions',
      featuredImage: '/images/blog1-depoy-s3-gitaction.webp',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "07",
      createMonth: 'February',
      createYear: "2022"
    }
  ],
  contactInfo: {
    phoneNumbers: ['+1 641-233-9310'],
    emailAddress: ['bir_yem@yahoo.com', 'yeman.biruk@gmail.com'],
    address: "1000 N 4th St, Fairfield, Iowa, USA"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});